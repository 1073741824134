import styled from 'styled-components';
import { black, white } from '../../constants/theme';
import { Theme } from '../../store/types';

type Props = {
  contrast?: boolean,
  useContrast?: boolean,
  themeKey?:
    | 'home'
    | 'visualization'
    | 'panorama'
    | 'orange'
    | 'projects'
    | 'downloads'
    | 'methods'
    | 'about',
};

export const HomeTitle = styled.h1`
  color: ${white};
  font-style: normal;
  font-weight: 300;
  font-size: 10rem;
  line-height: 10rem;
  max-width: 110rem;
  margin-bottom: 4rem;
`;

const setColor = ({ themeKey = 'home', useContrast, theme }: Props & Theme) => {
  if (useContrast) {
    return theme.palette[themeKey].contrastText;
  }

  return theme.palette.white;
};

export const Title = styled.h1`
  color: ${setColor};
  font-weight: 300;
  font-size: 56px;
  line-height: 67px;
`;

export const SectionTitle = styled.h3`
  color: ${setColor};
  font-weight: 300;
  font-size: 56px;
  line-height: 67px;
`;

export const SubTitle = styled.h2`
  color: ${setColor};
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
`;

export const Text = styled.h1`
  color: ${black};
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
`;
